import axios from 'axios'
import Utils from '../services/Utils'

axios.defaults.timeout = 900000
axios.defaults.dev = false

const queryString = window.location.search
const urlParams = new URLSearchParams(queryString)
const isDev = urlParams.get('dev') ? true : false

let baseUrl = 'https://ptsrestapi.mkgest.com:5757/api/v1/'
let tgBaseUrl = 'https://ptsapi.mkgest.com:3535/api/v1/'
let rpBaseUrl = 'https://ruipinheiro-api.mkgest.com/api/v1/'
let fmBaseUrl = 'https://macauapi.mkgest.com/api/v1/'
let rzBaseUrl = 'https://rizeapi.mkgest.com/api/v1/'

if (axios.defaults.dev || isDev) {
    baseUrl = 'https://ptsrestapi.mkgest.com:4646/api/v1/'
    tgBaseUrl = 'https://ptsapi.mkgest.com:2424/api/v1/'
    rpBaseUrl = 'https://ruipinheiro-api.mkgest.com:2503/api/v1/'
    fmBaseUrl = 'https://macauapi.mkgest.com:2403/api/v1/'
    rzBaseUrl = 'https://rizeapi.mkgest.com:2427/api/v1/'
}

window.ptDemoId = -1

window.serverPtsIds = [{
    name: 'Tiago Guimarães',
    id: -3,
    url: tgBaseUrl
}, {
    name: 'Rui Pinheiro',
    id: -15,
    url: rpBaseUrl
}, {
    name: 'Francisco Macau',
    id: -28,
    url: fmBaseUrl
}, {
    name: 'Rize',
    id: -29,
    url: rzBaseUrl
}]

export default {
    getBaseUrl(ptId){
        const found = window.serverPtsIds.find(i => i.id === ptId)
        if (found) {
            return found.url
        }
        return baseUrl
    },
    login(data, callback) {
        Utils.updateAuthorization(data)
        axios.post(baseUrl + 'login?website=1',
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    logout(callback) {
        axios.post(baseUrl + 'logout?website=1&lng=' + window.language, {}, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getStrings(callback) {
        axios.get(baseUrl + 'translations?website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClients(ptId, data, callback) {
        axios.get(this.getBaseUrl(ptId) + 'user?filter={"type":0}' + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + '&photo=0&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getActiveClients(ptId, data, callback) {
        axios.get(this.getBaseUrl(ptId) + 'user?filter={"type":0, "status":0}' + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + '&photo=0&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClient(ptId, data, callback) {
        axios.get(this.getBaseUrl(ptId) + 'user?filter={"type":0,"id":' + data.id + '}' + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + '&photo=0&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getCurrentPayments(ptId, callback) {
        const today = new Date()
        const year = today.getFullYear()
        const month = today.getMonth() + 1
        axios.get(this.getBaseUrl(ptId) + 'pt_payment?filter={"year":' + year + ',"month":' + month + '}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClientLastPayment(ptId, id, callback) {
        const today = new Date()
        const year = today.getFullYear()
        const month = today.getMonth() + 1
        axios.get(this.getBaseUrl(ptId) + 'pt_payment?filter={"id":' + id + ',"year":' + year + ',"month":' + month + '}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getClientPayments(ptId, id, callback) {
        axios.get(this.getBaseUrl(ptId) + 'pt_payment?filter={"id":' + id + '}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateClientPayment(ptId, data, callback) {
        const today = new Date()
        const year = today.getFullYear()
        const month = today.getMonth() + 1
        axios.patch(this.getBaseUrl(ptId) + 'pt_payment?id=' + data.id + '&year=' + year + '&month=' + month + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getUser(ptId, data, callback) {
        axios.get(this.getBaseUrl(ptId) + 'user?filter={"pt_id":' + data.pt_id + ', "id":' + data.id + '}&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateUser(ptId, data, callback) {
        axios.patch(this.getBaseUrl(ptId) + 'user?id=' + data.id + '&pt_id=' + data.pt_id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deleteUser(ptId, data, callback) {
        axios.delete(this.getBaseUrl(ptId) + 'user?id=' + data.id + '&pt_id=' + data.pt_id + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateRegister(ptId, data, callback) {
        axios.patch(this.getBaseUrl(ptId) + 'register?id=' + data.id + '&pt_id=' + data.pt_id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    decryptPassword(ptId, password, callback) {
        axios.post(this.getBaseUrl(ptId) + 'pwd_dec?&website=1&lng=' + window.language, {
            password: password,
        }, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getPTBlockedClients(ptId, fields, callback) {
        axios.get(this.getBaseUrl(ptId) + 'user?filter={"pt_id":' + ptId + ',"type":1,"status":2}&website=1&lng=' + window.language + '&fields=[' + fields + ']', {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getPTRegisters(ptId, data, callback) {
        axios.get(this.getBaseUrl(ptId) + 'register?search={"pt_id":[' + data.ptId + '], "status":' + JSON.stringify(data.status) + '}' + (data.fields ? ('&fields=' + JSON.stringify(data.fields)) : '') + (data.limit ? '&limit=' + data.limit : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    getReportIssues(ptId, data, callback) {
        axios.get(this.getBaseUrl(ptId) + 'issue_report?' + (data.start_date && data.end_date ? 'query=[{"f":"date","q":5,"v":"' + data.start_date + '","d":1,"tz":0,"n":1},{"f":"date","q":4,"v":"' + data.end_date + '","d":1,"tz":' + window.diffUtcHours + ',"n":0}]' : '') + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    newReportIssue(ptId, data, callback) {
        axios.post(this.getBaseUrl(ptId) + 'issue_report?website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    updateReportIssue(ptId, data, callback) {
        axios.patch(this.getBaseUrl(ptId) + 'issue_report?id=' + data.id + '&website=1&lng=' + window.language,
            data, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
    deleteReportIssue(ptId, data, callback) {
        axios.delete(this.getBaseUrl(ptId) + 'issue_report?id=' + data.id + '&website=1&lng=' + window.language, {
            headers: {
                'Content-Type': 'application/json',
                Authorization: axios.defaults.authorization,
            },
        }).then((response) => {
            Utils.processResponse(response, callback)
        }).catch((response) => {
            Utils.processResponse(response, callback)
        })
    },
}
